<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle>Сделать команду дочерней</VCardTitle>
          <VCardText>
            <VForm>
              <VTextField
                v-model="childId"
                label="id команды"
              />
            </VForm>
          </VCardText>

          <VCardActions class="px-4">
            <VBtn
              class="mr-3"
              depressed
              color="primary"
              @click="createLinkToTeam"
            >
              Добавить
            </VBtn>
            <VBtn
              text
              depressed
              @click="cancel"
            >
              Отмена
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'AddChildToTeamForm',

  data() {
    return {
      childId: '',
    };
  },

  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
    companyId() {
      return this.$route.params.companyId;
    },
  },

  methods: {
    createLinkToTeam() {
      const { teamId: parentId, childId, companyId } = this;
      this.$di.api.Orgstructure
        .teamCreateLink({ parentId, childId, companyId })
        .then(() => {
          this.$di.notify.snackSuccess('Добавлена дочерняя команда');
          this.cancel();
        })
        .catch(this.$di.notify.errorHandler);
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
